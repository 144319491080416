<template>
  <div>
    <LoginForm />
  </div>
</template>

<script setup>
import { useClubStore } from '@/stores/club'
import { useUserStore } from '@/stores/user'
import { useActivationStore } from '@/stores/activation'
import { computed } from 'vue'
import { useProAreaStore } from '@/stores/proArea'
import { useRegistrationStore } from '@/stores/registration'
import { useEmitterStore } from '@/stores/emitter'

import LoginForm from '@/components/Login/LoginForm/LoginForm'

definePageMeta({
  auth: false,
  authClub: false,
  layout: computed(() => {
    const clubStore = useClubStore()
    const proAreaStore = useProAreaStore()
    return proAreaStore.getIsProDomain ? 'pro' : clubStore.layout
  }),
})

const { defaultHead, defaultSeo } = useDefaultMetaData({
  customKey: 'login',
})
useHead(defaultHead())
useSeoMeta(defaultSeo())

const registrationStore = useRegistrationStore()

const showWelcomeMessage = computed(() => {
  return registrationStore.getPendingConfirmation
})

const userStore = useUserStore()
if (userStore.isAuthenticated) {
  const localePath = useLocalePath()
  const router = useRouter()
  router.replace(localePath('index'))
}

onMounted(() => {
  const emitter = useEmitterStore()
  const { analytics, pageTemplate } = useAnalytics()
  analytics.sendScreen(pageTemplate.LOGIN)
  // Reset activation flow
  const activationStore = useActivationStore()
  activationStore.setPhone(null)
  activationStore.setEmailOrPhone(null)
  activationStore.setForgotPassword(false)
  if (showWelcomeMessage.value) {
    emitter.emit('modalOpen_registeredModal', 'registeredModal')
    registrationStore.setPendingConfirmation(false)
  }
})
</script>
